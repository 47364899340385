import { Wistia } from 'wistia_namespace.js';
import '../embeds/media/modules/_integrations.js';
import '../embeds/media/modules/_ga.js';
import '../embeds/media/modules/_hubspot.js';
import '../embeds/media/modules/_marketo.js';
import { registerPlugin } from '../embeds/media/modules/_hubspot_v2.js';

// This function should only ever be run once.
// the integrations code adds a `onFindApiHandleRunOnce` handler that will handle
// finding and initializing integrations that are added at a later time
// If this runs multiple times it can lead to multiple handlers and therefore
// multiple events firing undesirably.
export const runIntegrationsSetup = () => {
  if (Wistia.integrations._setup) {
    return;
  }

  registerPlugin();

  Wistia.integrations.setup();
  Wistia.bind('ev1initend', () => {
    Wistia._initializers.initIntegrations();
  });
  Wistia.bind('ev1destroystart', () => {
    Wistia._destructors.destroyIntegrations();
  });
};
